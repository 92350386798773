<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form label-width="120px" :model="formLabelAlign" class="from">
            <el-form-item label="标题：">
              <el-input
                v-model="formLabelAlign.title"
                placeholder="请输入标题"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个记录</span>
            <span class="span2">已选择 {{ more_List.length }} 个记录</span>
            <i @click="way_tableData" class="span_i el-icon-refresh"
              >数据更新</i
            >
          </p>
          <div>
            <el-button
              type="primary"
              @click="on_pop_addplus()"
              icon="el-icon-plus"
              >发布</el-button
            >
          </div>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
            <el-table-column prop="fatime" label="检查人员">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  style="margin-right: 2px"
                  v-for="(item, index) in scope.row.personStrname"
                  :key="index"
                  >{{ item }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column prop="fatime" label="发布日期"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-link
                  class="list_sty"
                  type="primary"
                  @click="on_particulars(scope.row)"
                  >查看更多</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
      <el-dialog title="添加" :visible.sync="dialogFormVisible">
        <el-form :model="form" label-width="80px">
          <el-form-item label="检查人员">
            <el-select
              v-model="form.personStr"
              multiple
              placeholder="请选择检查人员"
            >
              <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.nickname"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="form.title" placeholder="请输入标题"></el-input>
          </el-form-item>
          <el-form-item label="发布时间">
            <el-date-picker
              v-model="form.fatime"
              type="datetime"
              placeholder="选择日期时间"
            ></el-date-picker>
          </el-form-item>
          <!-- <el-form-item prop="department_id" label="部门">
                    <BepartmentMulti style="margin-top: 5px;" ref="BepartmentMulti" />
                </el-form-item> -->
          <el-form-item label="检查内容">
            <div
              class="items"
              style="margin-bottom: 10px"
              v-for="(item, index) in form.contentJson"
              :key="index"
            >
              <el-input
                style="width: 150px; margin-right: 20px"
                v-model="item.name"
                placeholder="请输入内容"
              ></el-input>
              <div style="margin-left: 20px">
                <el-button @click="additems(index)">添加</el-button>
                <el-button type="danger" @click="delitems(index)" plain
                  >删除</el-button
                >
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="onsubmit()">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="详情"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <div class="boxs">
          <div class="item">
            <div class="lable">标题：</div>
            <div class="cont">{{ details.title }}</div>
          </div>
          <div class="item">
            <div class="lable">检查人员：</div>
            <div class="cont">
              <el-tag
                size="mini"
                style="margin-right: 4px"
                v-for="(items, indexs) in details.personStrname"
                :key="indexs"
                >{{ items }}</el-tag
              >
            </div>
          </div>
          <div class="item">
            <div class="lable">发布时间：</div>
            <div class="cont">{{ details.fatime }}</div>
          </div>
          <div class="item">
            <div class="lable">检查内容：</div>
            <div class="cont">
              <el-tag
                size="mini"
                style="margin-right: 4px"
                v-for="(items, indexs) in details.contentJson"
                :key="indexs"
                >{{ items.name }}</el-tag
              >
            </div>
          </div>
        </div>

        <div
          class="user-gloup"
          v-for="(item, index) in details.signpic"
          :key="index"
        >
          <div class="sign-name">检查人姓名：{{ item.useridname }}</div>
          <div>
            <el-table
              highlight-current-row
              @current-change="way_singleselect"
              @selection-change="way_moreselect"
              border
              :data="item.ccontentJson"
              :header-cell-style="{ background: '#f8f8f9' }"
              style="flex: 1"
            >
              <el-table-column prop="name" label="设备名称"></el-table-column>
              <el-table-column prop="value" label="是否异常">
                <template slot-scope="scope">
                  <el-tag size="mini" type="danger" v-if="scope.row.value == 0"
                    >异常</el-tag
                  >
                  <el-tag size="mini" v-if="scope.row.value == 1">正常</el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="hhh">检查人签名：</div>
          <div class="sign">
            <div class="group-item">
              <div
                class="sign_pic"
                v-for="(itm, idx) in item.csignJson"
                :key="idx"
              >
                <img :src="itm" alt="" />
              </div>
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >关闭</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  
<script>
import table_minin from "../../assets/minin/table_minin";
import BepartmentMulti from "../../components/Bepartment_multi.vue";
import { specialList, userList, specialPost } from "../../assets/request/api";
export default {
  mixins: [table_minin],
  components: {
    BepartmentMulti
  },
  data() {
    return {
      formLabelAlign: {
        title: ""
      },
      options: [
        {
          id: 0,
          name: "待办"
        },
        {
          id: 1,
          name: "未办结"
        },
        {
          id: 2,
          name: "已办结"
        }
      ],
      dialogFormVisible: false,
      // status_list: [],
      // check_times: [],
      users: [],
      form: {
        personStr: [],
        title: "",
        fatime: "",
        // department_id:'',
        contentJson: [
          {
            name: ""
          }
        ]
      },
      dialogVisible: false,
      details: {}
    };
  },
  created() {
    this.url = specialList;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "type_list";
    this.way_tableData();
  },
  methods: {
    way_extend(data) {},
    //打开弹框
    on_pop_addplus() {
      this.form = {
        personStr: [],
        title: "",
        fatime: "",
        // department_id:'',
        contentJson: [
          {
            name: ""
          }
        ]
      };
      this.dialogFormVisible = true;
      userList().then(res => {
        this.users = res.data;
      });
      // inspectionsaveInfo().then((res)=>{
      //     let { status_list, check_times } = res.data;
      //     this.check_times = check_times;
      //     this.status_list = status_list;
      //     console.log('check_times'+this.status_list);
      // })
    },
    //提交
    onsubmit() {
      // let department_id = this.$refs.BepartmentMulti.way_pitch();
      // this.form.department_id = department_id;
      this.form.fatime = this.formatDateTime(this.form.fatime);
      specialPost(this.form).then(res => {
        if (res.code == 1) {
          this.$message({
            showClose: true,
            message: "操作成功",
            type: "success"
          });
          this.dialogFormVisible = false;
          this.way_tableData();
        }
      });
      console.log(this.form);
    },
    //添加一组
    additems() {
      let obj = {
        name: ""
      };
      this.form.contentJson.push(obj);
      console.log(this.form.contentJson);
    },
    //删除一组
    delitems(index) {
      if (this.form.contentJson.length > 1) {
        this.form.contentJson.splice(index, 1);
      } else {
        this.$message({
          showClose: true,
          message: "最后一条不能删除！",
          type: "warning"
        });
      }
    },
    //处理日期
    formatDateTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    on_particulars(row) {
      this.dialogVisible = true;
      this.details = row;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}

.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.form-inp {
  width: 200px;
}

.paging {
  text-align: center;
  padding: 10px;
}

.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    margin-right: 10px;
  }

  p {
    display: flex;
    align-items: center;
  }
}

.span1 {
  font-size: 14px;
  color: #000000;
}

.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}

.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}

.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.list_sty {
  margin-right: 10px;
}

.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px;
  color: #333;
}

.boxs {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 50%;
    display: flex;
    margin-bottom: 20px;

    .lable {
      white-space: nowrap;
    }
    .cont {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

::v-deep .el-dialog {
  overflow-y: scroll;
  max-height: 550px;
  min-width: none;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  .block {
    margin-right: 10px;
  }
}

::v-deep .el-dialog {
  min-width: 400px;
}

.items {
  display: flex;
  align-items: center;
}
.jc_cont {
  margin-bottom: 10px;
}
.hhh {
  font-weight: bold;
  margin: 15px 0;
}
.user-gloup {
  margin: 15px 0;
  .sign-name {
    margin-bottom: 10px;
    font-weight: bold;
  }
}
.group-item {
  display: flex;
  margin-bottom: 20px;
  .sign_pic {
    width: 280px;
    height: 100px;
    background-color: #f6f6f6;
    margin-right: 20px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
  